import "./App.css";
import Globe from "react-globe.gl";
import { useRef, useEffect } from "react";
import globeData from "./datasets/globedata";
import globeBg from "./assets/imgs/back1.png";

function App() {
  const globeEl = useRef();

  useEffect(() => {
    globeEl.current.pointOfView({ lat: 100, lng: -98.5, altitude: 1.7 });
    globeEl.current.controls().enableZoom = false;
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.7;
  }, []);

  return (
    <div className="App">
      <div className="tl">
        <div className="logo">
          <svg
            width="57"
            height="39"
            viewBox="0 0 38 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.2466 7.34523L23.0624 0.5H37.7497L34.8598 7.34523H20.2466Z"
              fill="#EEEFF2"
            />
            <path
              d="M16.7515 15.8271L18.9591 10.5195L28.9858 10.5195L26.704 15.8271H16.7515Z"
              fill="#EEEFF2"
            />
            <path
              d="M12.7705 25.4995L15.5863 18.6543H30.2736L27.3837 25.4995H12.7705Z"
              fill="#EEEFF2"
            />
            <path
              d="M0.25 7.39471L3.01345 0.5H20.3739L10.0603 25.4971H3.01345L10.0603 7.39471H0.25Z"
              fill="#EEEFF2"
            />
          </svg>
          <span className="name">seven exchange</span>
        </div>
        <p>
          <span className="title">Spot Market</span>
          Our spot market feature allows users to buy and sell assets for
          immediate settlement, providing real-time trading opportunities.
        </p>
        <p>
          <span className="title">OTC Market</span>
          With our OTC market functionality, users can trade financial
          instruments directly with counterparties, facilitating efficient and
          customized transactions.
        </p>
        <p>
          <span className="title">Futures Market</span>
          Experience the excitement of futures trading on our platform, where
          users can speculate on the future price of assets and manage risk
          through derivative contracts.
        </p>
        <p>
          <span className="title">P2P Market</span>
          Our peer-to-peer market enables direct transactions between users,
          fostering a decentralized trading environment and promoting community
          engagement.
        </p>
        <p>
          <span className="title">Swap</span>
          Seamlessly exchange cryptocurrencies with our crypto swap feature,
          offering users a quick and convenient way to convert between different
          digital assets.
        </p>
      </div>
      <div className="globe-container">
        <Globe
          ref={globeEl}
          globeImageUrl={globeBg}
          backgroundColor="rgba(0,0,0,0)"
          atmosphereColor="#474E68"
          hexPolygonsData={globeData.features}
          hexPolygonResolution={3}
          hexPolygonMargin={0.3}
          hexPolygonColor={() => `#474E68`}
          width={
            window.innerWidth > 600 ? window.innerWidth / 2 : window.innerWidth
          }
          hexPolygonLabel={({ properties: d }) => `
      <b>${d.ADMIN} (${d.ISO_A2})</b>
    `}
        />
      </div>
    </div>
  );
}

export default App;
